.warning_web_main_container {
  // background-color: red !important;

  .toggle_switch_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid red;

    p {
      margin: 0px;
      color: black;
      font-size: 20px;
    }
  }

  .text_area_container {
    width: 100%;
    margin-top: 20px;
    position: relative;
    label {
      color: gray !important;
      margin-left: 5px;
    }
    .text_area {
      width: 100%;
      padding: 10px;
      border-radius: 8px;
    }

    button {
      width: 126px;
      height: 52px;
      width: 126px;
      border: none;
      border-radius: 5px;
      position: absolute;
      right: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      border: 1px solid black;
      font-size: 600;
      font-size: 16px;

      &:hover {
        background-color: black;
        color: white;
        border: 1px solid white;
      }
    }
  }
}
