.game_list_container {
  .game_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    h4 {
      margin: 0px;
    }
    button {
      border: none;
      width: 150px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid black;
      background-color: white;
      &:hover {
        background-color: black;
        color: white;
      }
    }
  }
  .game_list_body {
    .games_list_container {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      // justify-content: space-between;
      width: 100%;
      @media only screen and (max-width: 1100px) {
        // gap: 50px;
      }
      .game_card {
        width: 320px;
        border: 1px solid lightgray;
        border-radius: 5px;
        // margin-bottom: 50px;
        @media only screen and (max-width: 700px) {
          width: 100%;
        }
        .game_name {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          // justify-content: center;
          border-bottom: 0.5px solid lightgray;
          P {
            margin: 0px;
            padding-left: 10px;
            font-size: 16px;
          }
        }
        .game_image {
          padding: 10px;

          img {
            width: 100%;
            object-fit: cover;
            height: 200px;
            @media only screen and (max-width: 700px) {
              height: 150px;
            }
          }
        }
        .game_action {
          border-top: 0.5px solid lightgray;
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          .game_switch {
            cursor: pointer;
          }
          .action_button {
            display: flex;
            gap: 10px;
            .icon {
              width: 25px;
              height: 25px;
              border: 0.5px solid lightgray;
              border-radius: 5px;
              cursor: pointer;
              &:hover {
                border: 0.5px solid black;
              }
              svg {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

.loading_container {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    color: black;
    margin-top: 10px;
    font-size: 16px;
  }
}
