.loading_container {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    color: black;
    margin-top: 10px;
    font-size: 16px;
  }
}

.settings_main_container {
  // background-color: red !important;
  // height: 85vh;
  // border: 1px solid red;
  // overflow: hidden;
  // overflow-y: auto;
  .text_area_container {
    width: 100%;
    margin-top: 60px;
    position: relative;
    .text_area {
      width: 100%;
      padding: 10px;
    }

    button {
      width: 126px;
      height: 52px;
      width: 126px;
      border: none;
      border-radius: 5px;
      position: absolute;
      right: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid black;

      &:hover {
        background-color: black;
        color: white;
      }
    }
  }
}

.settings_main_container {
  .text_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid red;

    p {
      margin: 0px;
      color: black;
      font-size: 20px;
      font-weight: 400;
    }
  }

  label {
    color: rgb(72, 72, 72) !important;
    margin-left: 5px;
  }
  .button_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      width: 126px;
      height: 52px;
      width: 126px;
      border: none;
      border-radius: 5px;
      border: 1px solid black;

      &:hover {
        background-color: black;
        color: white;
      }
    }
  }
  .contain {
    margin-bottom: 30px;
  }
  .text_area_contain {
    margin-top: 20px;
    .text_area {
      width: 100%;

      border-radius: 8px;
      padding: 10px;
      padding-left: 15px;
      border: 1px solid lightgray !important;
      &:focus {
        border: 1px solid black !important;
      }
    }
  }

  .body_container {
    width: 100%;
    margin-top: 20px;
    position: relative;
  }
  .commission_referral_body {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 20px;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
  .number_input_container {
    width: 100%;

    .input_and_icon {
      position: relative;
      overflow: hidden !important;
    }

    .number_input {
      width: 100%;
      padding: 10px;
      padding-left: 15px;
      border-radius: 8px;
      border: 1px solid lightgray !important;
      &:focus {
        border: 1px solid black !important;
      }
    }
    .percentage_icon {
      position: absolute;
      right: 0px;
      top: 0px;
      height: 80%;
      width: 50px;
      // border: 1px solid red;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 0.5px solid black;
      // border: 1px solid red;

      background-color: white;
      border-radius: 0px 8px 8px 0px;
      margin-top: 4px;
      margin-right: 2px;
    }
  }
  .border {
    border: 1px solid transparent !important;
  }
}
