.modal_body {
  width: 500px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  .number_input_container {
    width: 100%;

    label {
      color: rgb(72, 72, 72) !important;
      margin-left: 5px;
    }
    .number_input {
      width: 100%;
      padding: 10px;
      padding-left: 15px;
      border-radius: 8px;
      border: 1px solid lightgray !important;
      &:focus {
        border: 1px solid black !important;
      }
    }
  }
  .file_upload {
    width: 100%;
    margin-top: 10px;
    label {
      color: rgb(72, 72, 72) !important;
      margin-left: 5px;
    }
    .number_input {
      width: 100%;
      padding: 10px;
      padding-left: 15px;
      border-radius: 8px;
      border: 1px solid lightgray !important;
      &:focus {
        border: 1px solid black !important;
      }
    }
  }
}

.image_upload_box {
  border: 1px solid lightgray !important;
  padding: 10px;
  padding-left: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0px;
  }
  .icon {
    width: 25px;
    height: 25px;
    border: 0.5px solid lightgray;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      border: 0.5px solid black;
    }
    svg {
      font-size: 18px;
    }
  }
}
.button_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    width: 126px;
    height: 40px;
    width: 126px;
    border: none;
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: black;
      color: white;
      border: 1px solid white;
    }
  }
}

.error_para {
  font-size: 12px;
  color: red;
  margin-left: 5px;
  margin-top: 2px;
  margin-bottom: 0px;
  opacity: 0;
}

.error_para_opacity {
  opacity: 1;
}
