.payment_screenshot {
  cursor: pointer;
}
.payment_btn {
  .action_btn {
    width: 80px;
    cursor: pointer;
    border: none;
    height: 30px;
    border-radius: 3px;
  }
  .approve_btn {
    background-color: #84d7aa;
    &:hover {
      background-color: green;
    }
  }

  .reject_btn {
    background-color: #e8a2a2;
    &:hover {
      background-color: red;
    }
  }
}
.table_heading {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 20px;
}

.table_container {
  background-color: white !important;
  border: 1px solid lightgray !important;
  border-radius: 5px;
  overflow: hidden;
}

.manual_payment_table {
  thead {
    background-color: rgb(241, 241, 241);
    border-bottom: 1px solid lightgray;
    tr {
      th {
        border: none;
      }
    }
  }
}

.display_none{
  display: none;
}
