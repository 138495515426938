:root {
  --primary:#d28264;
  --secondary: rgb(25 72 122);
  --mobile-secondary : rgb(33 80 130) ;
  --features:rgb(114 123 142 / 31.283815%);
  --date-formate:rgb(189 190 194 / 78.766542%);
  --question-head : #222222;
  --light-gray: rgb(177 177 177);
  --gray:rgb(87 97 114);
  --dark-gray: #313131;
  --border:rgb(207, 225, 225);
  --focus-border:rgb(150, 195, 221);
  --black:rgb(32, 33, 36);
  --category:rgb(177 177 177);
  --feature-icon-blue:#1A28A8;
  --light-blue:lightblue;
  --white: #ffffff;
  --button-color:rgb(159 162 173/59.214594%);
  --buttonSecondary: #ffffff;
  --button-border-light-gray:rgba(190, 190, 190, 0.26766);
  --error-primary: #ff3636;
  --success-message: #32cd32;
  --nav-bar-text: black;
  --all-bg-color:white;
  --all-border:#e8e9ea;
}


