.loading_container {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    color: black;
    margin-top: 10px;
    font-size: 16px;
  }
}

.payment_setting_container {
  .text_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid red;

    p {
      margin: 0px;
      color: black;
      font-size: 20px;
      font-weight: 400;
    }
  }

  label {
    color: rgb(72, 72, 72) !important;
    margin-left: 5px;
  }
  .button_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      width: 126px;
      height: 52px;
      width: 126px;
      border: none;
      border-radius: 5px;
      border: 1px solid transparent;

      &:hover {
        background-color: black;
        color: white;
        border: 1px solid white;
      }
    }
  }
  .contain {
    margin-bottom: 30px;
  }
  .text_area_contain {
    margin-top: 20px;
    .text_area {
      width: 100%;

      border-radius: 8px;
      padding: 10px;
      padding-left: 15px;
      border: 1px solid lightgray !important;
      &:focus {
        border: 1px solid black !important;
      }
    }
  }

  .body_container {
    width: 100%;
    margin-top: 20px;
    position: relative;
  }
  .commission_referral_body {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-direction: column;
    gap: 20px;
  }
  .number_input_container {
    width: 100%;

    .input_and_icon {
      position: relative;
      overflow: hidden !important;
    }

    .number_input {
      width: 100%;
      padding: 10px;
      padding-left: 15px;
      border-radius: 8px;
      border: 1px solid lightgray !important;
      &:focus {
        border: 1px solid black !important;
      }
    }
    .percentage_icon {
      position: absolute;
      right: 0px;
      top: 0px;
      height: 80%;
      width: 50px;
      // border: 1px solid red;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 0.5px solid black;
      // border: 1px solid red;

      background-color: white;
      border-radius: 0px 8px 8px 0px;
      margin-top: 4px;
      margin-right: 2px;
    }
  }
  .border {
    border: 1px solid transparent !important;
  }

  .image_upload_container {
    width: 100%;
    margin-top: 30px;
    .image_upload {
      width: 100%;
      border: 1px dashed lightgray;
      border-radius: 8px;
      position: relative;
      input {
        width: 100%;
        height: 200px;
        cursor: pointer;
        opacity: 0;
      }

      .upload_icon {
        position: absolute;
        top: 42%;
        left: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: -1;
        p {
          margin-bottom: 5px;
        }
      }
    }
    .qr_image_container {
      width: 100%;
      padding: 20px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid lightgray !important;
      position: relative;
      .delete_icon {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        border: 0.5px solid gray;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        svg{
          color: gray;
        }
        &:hover{
          border: 0.5px solid black; 
          svg{
            color: black;
          }
        }
      }
      img {
        // width: 100%;
        height: 300px;
        // object-fit: cover;
        @media only screen and (max-width: 600px) {
          height: 200px;
        }
      }
    }
  }

  .button_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    button {
      width: 126px;
      height: 52px;
      width: 126px;
      border: none;
      border-radius: 5px;
      border: 1px solid black;

      &:hover {
        background-color: black;
        color: white;
        border: 1px solid white;
      }
    }
  }
}

.error_para {
  font-size: 12px;
  color: red;
  margin-left: 5px;
  margin-top: 2px;
  margin-bottom: 0px;
  opacity: 0;
}

.error_para_opacity {
  opacity: 1;
}
